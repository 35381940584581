// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journals-index-js": () => import("./../../../src/pages/journals/index.js" /* webpackChunkName: "component---src-pages-journals-index-js" */),
  "component---src-pages-journals-journals-style-js": () => import("./../../../src/pages/journals/journals.style.js" /* webpackChunkName: "component---src-pages-journals-journals-style-js" */),
  "component---src-pages-lidworden-bedankt-index-js": () => import("./../../../src/pages/lidworden-bedankt/index.js" /* webpackChunkName: "component---src-pages-lidworden-bedankt-index-js" */),
  "component---src-pages-lidworden-index-js": () => import("./../../../src/pages/lidworden/index.js" /* webpackChunkName: "component---src-pages-lidworden-index-js" */),
  "component---src-pages-posts-index-js": () => import("./../../../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-posts-posts-style-js": () => import("./../../../src/pages/posts/posts.style.js" /* webpackChunkName: "component---src-pages-posts-posts-style-js" */),
  "component---src-pages-topics-index-js": () => import("./../../../src/pages/topics/index.js" /* webpackChunkName: "component---src-pages-topics-index-js" */),
  "component---src-pages-topics-topics-style-js": () => import("./../../../src/pages/topics/topics.style.js" /* webpackChunkName: "component---src-pages-topics-topics-style-js" */),
  "component---src-templates-afdelingen-js": () => import("./../../../src/templates/afdelingen.js" /* webpackChunkName: "component---src-templates-afdelingen-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-journal-js": () => import("./../../../src/templates/journal.js" /* webpackChunkName: "component---src-templates-journal-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

